export const setCookie = (name, value, days) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

export const deleteAllCookies = () => {
  document.cookie.split(";").forEach((cookie) => {
    const cookieName = cookie.split("=")[0].trim();
    document.cookie = `${cookieName}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
};

export const isMobileAppHaveSavedToken = () => {
  try {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ fn: "isMobileAppHaveSavedToken", data: {} }));
  } catch (error) {
    // alert(error);
  }
};

export const isInMobileApp = (location) => {
  try {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ fn: "isInMobileApp", data: { location } }));
  } catch (error) {
    // alert(error);
  }
};

export const generateAndSaveMobileAppToken = (mobileNumber, yob, isForceOpen = false) => {
  try {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({ fn: "generateAndSaveMobileAppToken", data: { mobileNumber, yob, isForceOpen } })
    );
  } catch (error) {
    // alert(error);
  }
};

export const disableFaceIdLogin = () => {
  try {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ fn: "disableFaceIdLogin", data: {} }));
  } catch (error) {
    // alert(error);
  }
};

export const mobileLogout = () => {
  try {
    window.ReactNativeWebView?.postMessage(JSON.stringify({ fn: "logout", data: {} }));
  } catch (error) {
    // alert(error);
  }
};
