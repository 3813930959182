import { useEffect, useRef, useState, useContext } from "react";
import LoginStyle from "../../styles/Login.module.scss";
import AuthService from "../../services/auth.service";
import SecurityAnimation from "./SecurityAnimation";
import Decrypt from "../../utils/encrypt";
import Captcha from "./Captcha";

import lockTopImage from "../../public/images/lockLoginTop.svg";
import lockBottomImage from "../../public/images/lockLoginBottom.svg";
import shieldImage from "../../public/images/sheild.svg";
import { GlobalContext } from "../../pages/globalState";

const OTPForm = ({
  setSecurityPhase,
  onGoToDashboard,
  loginAttempts,
  emailAddress,
  countryCode,
  phoneNumber,
  yearOfBirth,
  otpToken,
  goBack,
}) => {
  const LoginFailed = `We couldn't find an account matching the following mobile number and birth year. Please try again or contact us at <a href="mailto:gti@gti-inv.com">gti@gti-inv.com</a>.`;
  const TempBlocked = `You have reached the login attempts limit. Please try again later or contact us at <a href="mailto:gti@gti-inv.com">gti@gti-inv.com</a>.`;
  const SMSBlocked = "You have reached the SMS re-send limit, and the service is temporarily locked. Please try again later.";

  const { t } = useContext(GlobalContext);
  const [isAnimateEmail, setIsAnimateEmail] = useState(false);
  const [otpIframeToken, setOtpIframeToken] = useState(null);
  const [hadLoginError, setHadLoginError] = useState(null);
  const [isAnimateSms, setIsAnimateSms] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [otpExpired, setOtpExpired] = useState(false);
  const [isValidOtp, setIsValidOtp] = useState(null);
  const [otpAttempt, setOTPAttempt] = useState(0);
  const [isResend, setIsResend] = useState(false);
  const [sendType, setSendType] = useState("sms");
  const [email, setEmail] = useState("");

  const MAX_TEMP_LOGIN_BLOCK = 5;
  const CAPTCHA_COUNT = 2;

  const otpInputs = useRef();
  let otpTimer = null;

  const resendCodeCallback = async () => {
    try {
      const token = await AuthService.findUserByPhoneNumberAndYOB({
        phoneNumber: `${countryCode}${clearLeadingZero(phoneNumber)}`,
        yob: yearOfBirth,
        type: sendType,
      });

      setOtpIframeToken(token.otpToken);
      setHadLoginError("A new code was sent.");

      clearTimeout(otpTimer);

      otpTimer = setTimeout(() => setOtpExpired(true), 60 * 2000);

      setTimeout(() => (sendType === "sms" ? setIsAnimateEmail(true) : setIsAnimateSms(true)), 2000);
    } catch (e) {
      if (e.response.status === 403 && e.response.data.error === "SMS_BLOCKED") {
        goBack();
        setHadLoginError(SMSBlocked);
      } else if (e.response.status === 403 && e.response.data.error === "BLOCKED") {
        goBack();
        setHadLoginError(TempBlocked);
      }
    }
  };

  const resendCode = async () => {
    setOtpExpired(false);
    clearTimeout(otpTimer);
    setIsResend(true);
    setShowCaptcha(true);

    setIsAnimateSms(false);
    setIsAnimateEmail(false);
  };

  const resendCodeViaSms = async () => {
    setSendType("sms");
    resendCode();
  };

  const resendCodeViaEmail = async () => {
    setOtpExpired(false);
    clearTimeout(otpTimer);
    setIsResend(true);

    const res = await AuthService.findUserByPhoneNumberAndYOB({
      phoneNumber: `${countryCode}${clearLeadingZero(phoneNumber)}`,
      yob: yearOfBirth,
      type: "email",
    });

    setSendType("email");
    setEmail(res.email);

    setIsAnimateEmail(false);
    setTimeout(() => setIsAnimateSms(true), 2000);
  };

  const preloadImage = function (image) {
    const img = new Image();
    img.src = location.href + image;
  };

  useEffect(() => {
    preloadImage(lockBottomImage.src);
    preloadImage(lockTopImage.src);
    preloadImage(shieldImage.src);
    setEmail(emailAddress);

    setTimeout(() => setIsAnimateEmail(true), 2000);
  }, []);

  useEffect(() => {
    setOtpIframeToken(otpToken);

    const incomingOTP = async (ev) => {
      if (ev.isTrusted) {
        if (ev.origin === "https://www.google.com") return;

        let result = {};

        try {
          result = JSON.parse(ev.data);
        } catch (error) {
          console.log(error);
        }

        if (result.src === "otp") {
          if (result.status) {
            try {
              await AuthService.otpVerify({
                phoneNumber: `${countryCode}${clearLeadingZero(phoneNumber)}`,
                token: otpIframeToken,
              });
              verifyOTP(result.userKeys);
            } catch (e) {}
          } else {
            try {
              const token = await AuthService.otpVerify({
                phoneNumber: `${countryCode}${clearLeadingZero(phoneNumber)}`,
                token: otpIframeToken,
              });
            } catch (e) {
              if (e.response.status === 403 && e.response.data.error === "BLOCKED") {
                goBack();
                setHadLoginError(TempBlocked);
              } else if (e.response.status === 403 && e.response.data.error === "SMS_BLOCKED") {
                goBack();
                setHadLoginError(SMSBlocked);
              }
            }

            invalidOTPAttempt(result);
            setIsValidOtp(false);
            setHadLoginError("The code was wrong.");
          }
        }
      }
    };

    const invalidOTPAttempt = () => {
      setOTPAttempt(otpAttempt + 1);
    };

    if (!otpTimer) {
      otpTimer = setTimeout(() => {
        setOtpExpired(true);
      }, 60 * 2000);
    }

    window.addEventListener("message", incomingOTP);
    return () => window.removeEventListener("message", incomingOTP);
  }, [otpAttempt, otpIframeToken]);

  useEffect(() => {
    let attempts = otpAttempt + loginAttempts;
    if (attempts === CAPTCHA_COUNT) {
      setShowCaptcha(true);
      setIsAnimateSms(false);
      setIsAnimateEmail(false);
    } else if (attempts === MAX_TEMP_LOGIN_BLOCK) {
      setHadLoginError(TempBlocked);
    }
  }, [otpAttempt]);

  const captchaResults = async (result) => {
    if (!result) {
      return;
    }

    setShowCaptcha(false);

    if (isResend) {
      await resendCodeCallback();
    }
  };

  const setUsersMetaData = async () => {
    const UserService = (await import("../../services/user.service")).default;
    const metaData = await UserService.getUserMetaData();

    if (Array.isArray(metaData)) {
      let decryptedAccounts = metaData
        .map((account) => Decrypt.decryptKey(account.accountId, account.encrypted))
        .filter((account) => account !== null);
      let userObject = decryptedAccounts[0];

      localStorage.setItem(
        "user",
        JSON.stringify({ ...userObject, yearOfBirth, phoneNumber: `${countryCode}${clearLeadingZero(phoneNumber)}` })
      );
    } else {
      localStorage.setItem(
        "user",
        JSON.stringify({ ...metaData, yearOfBirth, phoneNumber: `${countryCode}${clearLeadingZero(phoneNumber)}` })
      );
    }
    return true;
  };

  const verifyOTP = async (keys) => {
    try {
      localStorage.setItem("keys", JSON.stringify(keys));
      let isValid = await setUsersMetaData();
      if (isValid) setIsValidOtp(true);
    } catch (e) {
      setHadLoginError(LoginFailed);
    }
  };

  const clearLeadingZero = (value) => {
    return value.toString().replace(/^0/, "");
  };

  return showCaptcha ? (
    <Captcha
      phoneNumber={countryCode + clearLeadingZero(phoneNumber)}
      setSecurityPhase={setSecurityPhase}
      captchaResults={captchaResults}
    />
  ) : !isValidOtp ? (
    <div>
      <form onSubmit={(ev) => verifyOTP(ev)} key="otpFOrm" id={LoginStyle.otpForm}>
        {sendType === "sms" ? (
          <p id={LoginStyle.otpInstructions}>
            {t("please-provide-the-6-digit-sms")}
            <br />
            {localStorage.getItem("language") !== "he" && "+"}
            {countryCode}
            {clearLeadingZero(phoneNumber)}
            {localStorage.getItem("language") === "he" && "+"}
          </p>
        ) : (
          <p id={LoginStyle.otpInstructions}>
            {t("please-provide-the-6-digit-mail")}
            <br /> {email}
          </p>
        )}
        <a href="#" onClick={(ev) => resendCode()} id={LoginStyle.resendCode}>
          {t("resend-code")}
        </a>
        <div id={LoginStyle.otpInputs} ref={otpInputs}>
          {!otpExpired ? (
            <iframe src={`${process.env.NEXT_PUBLIC_AUTH_IFRAME}${otpIframeToken}`} key={otpIframeToken} id={LoginStyle.otpIframe} />
          ) : (
            <p id={LoginStyle.loginError} style={{ marginTop: "1rem" }}>
              {sendType === "sms" ? t("expired-mobile") : t("expired-mail")}
            </p>
          )}
        </div>

        {hadLoginError !== null && (
          <p id={LoginStyle.loginError} style={{ marginTop: "1rem" }} dangerouslySetInnerHTML={{ __html: hadLoginError }} />
        )}
      </form>

      {sendType == "sms" ? (
        email !== "" && (
          <div
            style={{ marginTop: "46px", opacity: isAnimateEmail ? 1 : 0, transition: `opacity ${isAnimateEmail ? "0.5s" : "0s"} ease-in` }}
          >
            <b>{t("sms-not-arriving-1")}</b> {t("sms-not-arriving-2")}
            <a
              style={{
                textDecoration: "none",
                color: "var(--blue)",
                marginRight: "5px",
                marginLeft: "5px",
                display: "inline",
                fontWeight: 600,
              }}
              onClick={() => resendCodeViaEmail()}
              href="#"
            >
              {t("sms-not-arriving-3")}
            </a>
            {t("sms-not-arriving-4")}
          </div>
        )
      ) : (
        <div style={{ marginTop: "46px", opacity: isAnimateSms ? 1 : 0, transition: `opacity ${isAnimateSms ? "0.5s" : "0s"} ease-in` }}>
          <b>{t("mail-not-arriving-1")}</b> {t("mail-not-arriving-2")}
          <a
            style={{
              textDecoration: "none",
              color: "var(--blue)",
              marginRight: "5px",
              marginLeft: "5px",
              display: "inline",
              fontWeight: 600,
            }}
            onClick={() => resendCodeViaSms()}
            href="#"
          >
            {t("mail-not-arriving-3")}
          </a>
          {t("mail-not-arriving-4")}
        </div>
      )}
    </div>
  ) : (
    <SecurityAnimation
      shieldImage={shieldImage}
      lockTopImage={lockTopImage}
      lockBottomImage={lockBottomImage}
      animationCallback={onGoToDashboard}
    />
  );
};

export default OTPForm;
