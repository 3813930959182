import axios from "axios";

let SERVER_URL = process.env.NEXT_PUBLIC_RUNNING_AT !== "local" ? "" : "http://localhost:9090";

// if (process.env.NEXT_PUBLIC_RUNNING_AT === "development") {
//   SERVER_URL = "https://gtitry.com";
// }

const config = {
  baseURL: SERVER_URL + "/auth",
  withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default _axios;
