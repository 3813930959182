import { useEffect, useRef, useState, useContext } from "react";
import LoginStyle from "../../styles/Login.module.scss";
import { GlobalContext } from "../../pages/globalState";

const SecurityAnimation = ({ animationCallback, lockTopImage, lockBottomImage, shieldImage }) => {
  const { t } = useContext(GlobalContext);

  const [phaseText, setPhaseText] = useState(t("processing"));
  const [animationDone, setAnimationDone] = useState(false);
  const [animationPhaseTwo, setAnimationDonePhaseTwo] = useState(false);
  const topLock = useRef();
  const sheild = useRef();
  const lockIcon = useRef();

  useEffect(() => {
    // requestAnimationFrame Shim
    (function () {
      const requestAnimationFrame =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame;
      window.requestAnimationFrame = requestAnimationFrame;
    })();

    const canvas = document.getElementById("canvas");
    const context = canvas.getContext("2d");
    const x = canvas.width / 2;
    const y = canvas.height / 2;
    const radius = 60;
    const endPercent = 102;
    let curPerc = 0;
    const circ = Math.PI * 2;
    const quart = Math.PI / 2;
    const grad = context.createLinearGradient(x, y, 300, 120);
    grad.addColorStop(0, "#69BC7B");
    grad.addColorStop(1, "#36B872");

    context.lineWidth = 5;
    context.strokeStyle = grad;

    const animate = (current) => {
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.beginPath();
      context.arc(x, y, radius, -quart, circ * current - quart, false);
      context.stroke();
      curPerc++;
      if (curPerc < endPercent && !animationDone) {
        requestAnimationFrame(() => {
          // setTimeout(() => {
          animate(curPerc / 100);

          // }, Math.random() > 0.9 ? Math.random() * 250 : 0)
        });
      } else {
        setAnimationDone(true);
        setTimeout(() => {
          setPhaseText(t("approved"));
          setAnimationDonePhaseTwo(true);
          setTimeout(() => {
            animationCallback();
          }, 1000);
        }, 500);
      }
    };

    animate();
  }, []);

  return (
    <div id={LoginStyle.loginAnimation}>
      <div id={LoginStyle.lockAnimation} ref={lockIcon} className={animationPhaseTwo ? LoginStyle.hideLock : ""}>
        <img src={lockTopImage.src} id={LoginStyle.top} ref={topLock} className={animationDone ? LoginStyle.locked : ""} />
        <img src={lockBottomImage.src} id={LoginStyle.bottom} />
      </div>

      <img src={shieldImage.src} id={LoginStyle.shield} ref={sheild} className={animationPhaseTwo ? LoginStyle.showSheild : ""} />
      <canvas id="canvas" width="520" height="520" />

      <p>{phaseText}</p>
    </div>
  );
};

export default SecurityAnimation;
