import React from "react";
import { useState } from "react";
import { useRouter } from "next/router";

import LoginStyle from "../../styles/Login.module.scss";
import LoginForm from "./loginForm";
import OTPForm from "./otpForm";

const OTPLogin = (props) => {
  const router = useRouter();
  const [loginError, setLoginError] = useState(null);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaCallback, setCaptchaCallBack] = useState(null);
  const [invalidLoginTrials, setInvalidLoginTrials] = useState(0);

  const [state, setState] = useState({
    yearOfBirth: null,
    phoneNumber: null,
    wasOtpSent: false,
    countryCode: null,
    loginAttempts: 0,
    otpToken: null,
  });

  const resetLogin = () => {
    setState({
      yearOfBirth: null,
      phoneNumber: null,
      wasOtpSent: false,
      countryCode: null,
      otpToken: null,
    });
  };

  const goToDashboard = () => {
    // localStorageToken
    if (localStorage.getItem("activeAccount")) localStorage.removeItem("activeAccount");
    if (localStorage.getItem("activeAccountIndex")) localStorage.removeItem("activeAccountIndex");
    router.push("/dashboard");
  };

  const hadLoginError = (error) => {
    setLoginError(error);
  };

  const goBack = () => {
    setState((prevState) => {
      return {
        ...prevState,
        ...{
          wasOtpSent: false,
        },
      };
    });
  };

  const incrementLoginAttempt = () => {
    setInvalidLoginTrials(() => invalidLoginTrials + 1);
  };

  return (
    <div id={LoginStyle.loginArea}>
      {!state.wasOtpSent ? (
        <LoginForm setState={setState} setSecurityPhase={props.setSecurityPhase} key="identifier" />
      ) : (
        <OTPForm
          incrementLoginAttempt={incrementLoginAttempt}
          setInvalidLoginTrials={setInvalidLoginTrials}
          setSecurityPhase={props.setSecurityPhase}
          setCaptchaCallBack={setCaptchaCallBack}
          invalidLoginTrials={invalidLoginTrials}
          loginAttempts={state.loginAttempts}
          setShowCaptcha={setShowCaptcha}
          onGoToDashboard={goToDashboard}
          yearOfBirth={state.yearOfBirth}
          phoneNumber={state.phoneNumber}
          countryCode={state.countryCode}
          hadLoginError={hadLoginError}
          emailAddress={state.email}
          otpToken={state.otpToken}
          resetLogin={resetLogin}
          goBack={goBack}
          key="otp"
        />
      )}
    </div>
  );
};

export default OTPLogin;
