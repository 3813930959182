import aesjs from "aes-js";

function decrypt(data, key, iv, isPdf = false) {
  key = aesjs.utils.hex.toBytes(key);
  iv = aesjs.utils.hex.toBytes(iv);
  data = aesjs.utils.hex.toBytes(data);

  const aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
  const decryptedBytes = aesCbc.decrypt(data);

  if (!isPdf) {
    let decrypted = aesjs.utils.utf8.fromBytes(decryptedBytes);
    // decrypted = decrypted.replace(/\s*\"ProfileImageMetaData\" *: *\".*\"(,|(?=\s+\}))/, '')
    const extractedJson = decrypted.match(/({.*?})/g);
    if (!extractedJson || !extractedJson[0]) return null;

    return JSON.parse(extractedJson[0].replace(/[\x05]/g, "").trim());
  } else {
    return aesjs.utils.utf8.fromBytes(decryptedBytes);
  }
}

function decryptKey(accountId, data, pdf = null) {
  const isLocal = window.location.hostname === "localhost";
  if (isLocal) {
    try {
      return JSON.parse(data);
    } catch (error) {
      return null;
    }
  }

  const keys = localStorage.getItem("keys");
  const parsedKeys = JSON.parse(keys);
  const findKeys = parsedKeys.find((key) => key.accountId === accountId);

  if (!findKeys) {
    return null;
  }

  try {
    let dataToReturn = decrypt(data, findKeys.secretKey, findKeys.iv);
    if (pdf) {
      dataToReturn.pdf = decrypt(pdf, findKeys.secretKey, findKeys.iv, true);
    }

    return dataToReturn;
  } catch (e) {
    console.log(e);
    return null;
  }
}

function decryptPdf(accountId, pdf) {
  const isLocal = window.location.hostname === "localhost";
  if (isLocal) {
    return pdf;
  }

  const keys = localStorage.getItem("keys");
  const parsedKeys = JSON.parse(keys);
  const findKeys = parsedKeys.find((key) => key.accountId === accountId);

  if (!findKeys) {
    return null;
  }

  try {
    let dataToReturn = decrypt(pdf, findKeys.secretKey, findKeys.iv, true);
    return dataToReturn;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export default { decryptKey, decrypt, decryptPdf };
