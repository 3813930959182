import _axios from "./authBase";

class AuthService {
  constructor() {
    this.resource = "/auth";
  }

  async findUserByPhoneNumberAndYOB({ phoneNumber, yob, type = "sms" }) {
    return _axios.post(`${this.resource}/login`, { phoneNumber, yob, type });
  }

  async otpVerify({ phoneNumber, token }) {
    return _axios.post(`${this.resource}/otpVerify`, { phoneNumber, token });
  }

  async captchaResult({ value, phoneNumber }) {
    return _axios.post(`${this.resource}/captcha`, { value, phoneNumber });
  }

  async revalidateToken() {
    return _axios.post(`${this.resource}/revalidateToken`);
  }

  async logout() {
    return _axios.get(`${this.resource}/logout`);
  }
}

export default new AuthService();
