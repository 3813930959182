export const getCoverUrl = (coverImage) => {
  if (!coverImage) {
    return "";
  }

  if (coverImage.includes("https://")) {
    return coverImage;
  }

  return `${process.env.NEXT_PUBLIC_IMAGES}${coverImage}`;
};
