import ReCAPTCHA from "react-google-recaptcha";
import AuthService from "../../services/auth.service";
import { useEffect } from "react";

export const Captcha = ({ captchaResults, phoneNumber, setSecurityPhase }) => {
  useEffect(() => {
    setSecurityPhase("captcha");
  }, []);

  const gotValue = async (value) => {
    const captchaResult = await AuthService.captchaResult({ value, phoneNumber });
    captchaResults(captchaResult.status);
    if (captchaResult.status) setSecurityPhase("login");
  };

  return <ReCAPTCHA sitekey={process.env.NEXT_PUBLIC_SITE_KEY} onChange={gotValue} />;
};

export default Captcha;
